import "./home_test.css";
import {useEffect, useState} from "react";
import {Article} from "./article";
import {Link} from "react-router-dom"
import {Keyboard} from "../utils/keyboard";
import ModelViewer from "./modelViewer";

export function Home() {
    const [tags, setTags] = useState<string[]>(['tag1', 'tag2', 'tag3'])
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        loadArticles()
    }, [])

    function loadArticles(n: number = 8) {

    }

    return (
        <div className="home-ui">
       </div>
    )
}