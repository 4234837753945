import type {Review} from "../spaced_repetition/SRinterfaces";
import {QuoteObject} from "../quotes/quoteInterface";
import {Reference} from "../source_tracking_system/TrackingSystem";
import {SRCard} from "../spaced_repetition/interface";
import {TreeObject} from "../knowledgeGraph/types";

async function selectFromCardsDue(): Promise<any> {
    return await fetch(
        "https://fusionfrontier.tech/api/spaced_repetition/cards_due",
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                references: true
            })
        },
    )
        .then((response) => response.json()).then((data) => data.result);
}


async function postReviewProcessing(reviewData: Review): Promise<number> {
    return await fetch(
        "https://fusionfrontier.tech/api/spaced_repetition/post_review",
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(reviewData)
        })
        .then((response) => response.status);
}

async function getCardsByUmbrella(umbrella: string): Promise<any> {

    return await fetch(
        "https://fusionfrontier.tech/api/cards/umbrella",
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                umbrella: umbrella
            })
        },
    )
        .then((response) => response);
}


async function selectReviewedToday(): Promise<any> {
    return await fetch(
        "https://fusionfrontier.tech/api/DML/misc",
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                'statement': 'SELECT COUNT(*) FROM knowledge.review WHERE review_date=CURDATE();'
            })
        }
    )
        .then((response) => response.json());
}

async function cardCount(): Promise<any> {
    return await fetch(
        "https://fusionfrontier.tech/api/spaced_repetition/count",
        {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
        }
    )
        .then((response) => response.json());
}



async function searchTags(tags: string[]): Promise<any> {
   return await fetch(
        "https://fusionfrontier.tech/api/spaced_repetition/tags",
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                keywords: tags
            })
        }
    )
        .then((response) => response.json());
}





async function deleteCard(card: SRCard | undefined): Promise<number> {
    return await fetch(
    "https://fusionfrontier.tech/api/card/register_delete",
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(card)
        }
    ).then(response => response.status)
}

async function researchCard(card: any): Promise<number> {
    return await fetch(
        "https://fusionfrontier.tech/api/card/research",
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(card)
        }
    ).then(response => response.status)
}

async function formatCard(cardID: number): Promise<number> {
    return await fetch(
        "https://fusionfrontier.tech/api/card/status/update",
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({cardID: cardID, status: 'format'})
        }
    ).then(response => response.status)
}

async function fetchTree( ): Promise<TreeObject> {
    return await fetch(
        "https://fusionfrontier.tech/api/graph/fetch_tree",
        {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
        }
    ).then(response => response.json())
}

async function getCardsToUpdate(status: string): Promise<any> {
    return await fetch(
        "https://fusionfrontier.tech/api/card/status/get",
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({status: status})
        }
    ).then(response => response.status)
}




async function getCard(id: number): Promise<any> {
    return await fetch(
        `https://fusionfrontier.tech/api/get_card`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                cardID: id
            })
        }).then((response) => response.json()).then((data) => data.result);
}


async function addQuote(quote: string, author: string, tags: string="") {

    return await fetch(
        `https://fusionfrontier.tech/api/quotes/add`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                quote: quote,
                author: author,
                tags: tags
            })
        }).then((response) => response.status);
}

async function addReadingListItem(title: string, referenceType: string, authors?: string, notes?: string, tags?: string): Promise<number> {

    return await fetch(
        `https://fusionfrontier.tech/api/reading_list/add`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                title: title,
                authors: authors,
                notes: notes,
                referenceType: referenceType,
                tags: tags
            })
        }).then((response) => response.status);
}

async function getRandomQuote(): Promise<Array<QuoteObject>> {

    return await fetch(
        `https://fusionfrontier.tech/api/quotes/random`,
        {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            }
            // @ts-ignore
        }).then((response) => response.json())
        .then(data => data.result);
}

async function getAllFields(): Promise<string[]> {

    return await fetch(
        `https://fusionfrontier.tech/api/card/fields`,
        {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            }
            // @ts-ignore
        }).then((response) => response.json())
        .then(data => data.result);
}

async function getAllEvents(): Promise<string[]> {

    return await fetch(
        `https://fusionfrontier.tech/api/events/all`,
        {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            }
            // @ts-ignore
        }).then((response) => response.json())
        .then(data => data.result);
}

async function getRSSFeed(): Promise<any> {
    return await fetch(
        `https://fusionfrontier.tech/api/rss/get_feed`,
        {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            }
            // @ts-ignore
        }).then((response) => response.json())
        .then(data => data.result);
}

async function getAllReferences(): Promise<any> {
    return await fetch(
        `https://fusionfrontier.tech/api/references/all`,
        {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            }
            // @ts-ignore
        }).then((response) => response.json())
        .then(data => data.result);
}

async function getCardsFromReference(referenceID: number): Promise<any> {
    return await fetch(
        `https://fusionfrontier.tech/api/card/from_reference`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({"reference_id": referenceID})
        }).then((response) => response.json())
        .then((data) => data.result);
}


async function getCardsFromTag(tag: string): Promise<any> {
    return await fetch(
        `https://fusionfrontier.tech/api/card/from_tag`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({"tag": tag})
        }).then((response) => response.json())
        .then((data) => data.result);
}

async function getAllHabits(): Promise<any> {
    return await fetch(
        `https://fusionfrontier.tech/api/habits/all`,
        {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => response.json())
        .then((data) => data.result);
}

async function getAllGoals(): Promise<any> {
    return await fetch(
        `https://fusionfrontier.tech/api/goals/all`,
        {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => response.json())
        .then((data) => data.result);
}

export interface User {
    username: string;
    password: string;
}

async function login(user: User): Promise<any> {
    return await fetch(
        `https://fusionfrontier.tech/api/auth`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(user)
        }).then((response) => response.json())
        .then((data) => data.token)
        .then((token) => localStorage.setItem('authToken', token))
        .then(() => 200);
}


async function updateCard(card: any): Promise<any> {
    return await fetch(
        `https://fusionfrontier.tech/api/card/data/update`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(card)
        }).then((response) => response.status);
}

export {
    getRandomQuote,
    updateCard,
    login,
    getAllGoals,
    getAllHabits,
    getCardsFromReference,
    getCardsFromTag,
    getCardsToUpdate,
    addReadingListItem,
    selectFromCardsDue,
    getCardsByUmbrella,
    selectReviewedToday,
    searchTags,
    postReviewProcessing,
    deleteCard,
    getCard,
    cardCount,
    formatCard,
    researchCard,
    addQuote,
    getRSSFeed,
    getAllFields,
    getAllEvents,
    fetchTree
};
